import React, { createContext, useContext, useState, useEffect } from 'react';
import { Query } from 'appwrite';
import { 
    useGetAanpakkerVoorEenDagRequestList, 
    useGetTeamuitjeRequestList, 
    useGetHeldenavondRequestList,
    useGetUnavailableDateList

} from "../Hooks";

function formatDate(dateString) {
	const date = new Date(dateString);
	const day = date.getDate().toString().padStart(2, '0');
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const year = date.getFullYear().toString();
	return `${day}-${month}-${year}`;
  }


  
const CalendarEventContext = createContext();

// Create a provider component
export default function CalendarEventProvider({ children }) {

    const [stale, setStale] = useState({ stale: false });

    const now = new Date();
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

    const dateQuery = Query.greaterThan('date', firstDayOfMonth.toISOString());

    const [{ AanpakkerVoorEenDagRequestList, isLoading: AisLoading, isError: AisError }] = useGetAanpakkerVoorEenDagRequestList(stale, [dateQuery]);
    const [{ HeldenavondRequestList, isLoading: BisLoading, isError: BisError }] = useGetHeldenavondRequestList(stale, [dateQuery]);
    const [{ TeamuitjeRequestList, isLoading: CisLoading, isError: CisError }] = useGetTeamuitjeRequestList(stale, [dateQuery]);
    const [{ UnavailableDateList, isLoading: HisLoading, isError: HisError }] = useGetUnavailableDateList(stale, [dateQuery]);

    const [calendarEvents, setCalendarEvents] = useState({});
    const [participants, setParticipants] = useState({});
    const [unavailableDates, setUnavailableDates] = useState({});

    const reload = () => {
        setStale(x => !x);
    }

    useEffect(() => {
        let _calendarEvents = [];
        let _participants = {};
     
        AanpakkerVoorEenDagRequestList.filter(request => request.status !== 'rejected').forEach((item) => {
            _calendarEvents.push({ ...item, $$type: "aanpakker" });
        })
        HeldenavondRequestList.filter(request => request.status !== 'rejected').forEach((item) => {
            _calendarEvents.push({ ...item, $$type: "heldenavond" });
        })

        TeamuitjeRequestList.filter(request => request.status !== 'rejected').forEach((item) => {
            _calendarEvents.push({ ...item, $$type: "teamuitje" });
        })

        let groupedStructure = _calendarEvents.reduce((col, item) => {
            let events = col[formatDate(item.date)]
            if(!events) {
                events = {};
            }
            return ({...col, [formatDate(item.date)]: {...events, [item.$id]:item } })
        }, {});
        
        setCalendarEvents(groupedStructure);
        setParticipants(_participants);

    }, [
        AanpakkerVoorEenDagRequestList, 
        HeldenavondRequestList, 
        TeamuitjeRequestList
    ]);

    useEffect(() => {
        setUnavailableDates((UnavailableDateList || []).map(x => ({...x, date: formatDate(x.date)})));
    }, [UnavailableDateList])

    // Define any additional state or functions related to calendar events

    return (
        <CalendarEventContext.Provider value={{ 
            unavailableDates, 
            calendarEvents, 
            participants, 
            reload,
            aanpakkerVoorEenDagLoading: AisLoading,
            heldenavondLoading: BisLoading,
            teamuitjeLoading: CisLoading,
            unavailableDateLoading: HisLoading,
        }}>
            {children}
        </CalendarEventContext.Provider>
    );
};

export const useCalendarEventContext = () => {
    return useContext(CalendarEventContext);
};

