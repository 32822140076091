import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useState } from "react"
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { checkLunchAvailability } from '../api/backend.js';
import CheckboxInput from "./Forms/Controls/CheckboxInput";
import Counter from "./Forms/Controls/Counter";
import Tooltip from "./Elements/Tooltip";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

/**
 * Properties -> React.Element
 * @param properties.lunch Boolean
 * @param properties.partOfday String
 * @param properties.selectedDate Void | String
 * @param properties.setLunch Boolean -> Any
 * @param properties.setPartOfday String
 * @param properties.setVisitorCount Integer
 * @param properties.unavailableDayParts Object Any | Array String
 * @param properties.visitorCount Integer
 * @return React.Element
 */
export default function Preferences(properties) {

    const {
        selectedDate,
        unavailableDayParts,
        partOfday,
        setPartOfday,
        visitorCount,
        setVisitorCount,
        lunch,
        setLunch
    } = properties;

    const [lunchAvailable, setLunchAvailable]                   = useState(false);
    const [internalLunchPreference, setInternalLunchPreference] = useState(false);

    const onSelectedDate = () => {

        setLunchAvailable(false);

        if (selectedDate == null)
            return Function.prototype;

        const abortionController = new AbortController;

        const date = DateTime.fromFormat(selectedDate, 'dd-MM-yyyy');

        checkLunchAvailability(abortionController.signal, date)
            .then(available => {
                setLunch(available && internalLunchPreference);
                return available;
            })
            .then(setLunchAvailable);

        return abortionController.abort.bind(abortionController);

    };

    useEffect(onSelectedDate, [selectedDate]);

    const increaseVisitorCount = e => {
        if (visitorCount < 23) {
            setVisitorCount(visitorCount + 1);
        }
    }

    const decreaseVisitorCount = e => {
        if (visitorCount > 10) {
            setVisitorCount(visitorCount - 1);
        }
    }

    return (
        <div className="bg-[#FAFAFA] mx-2 mt-7 lg:mb-14">

            <h2 className="font-proxima font-black text-xl mb-2">2. Geef je voorkeuren aan</h2>

            <div className="shadow rounded-xl border-1 border-grey p-5 font-proxima bg-[#FFFFFF]">
                <div className="flex flex-col flex-wrap gap-2 lg:gap-4 mb-6">

                    <div>
                        <div className="font-bold">
                            Wat is het gewenste dagdeel voor de gekozen datum?*
                        </div>
                        {Array.isArray(unavailableDayParts)
                        && unavailableDayParts.length > 0
                        && <div className="font-light leading-tight">
                            {unavailableDayParts
                            .map(dayPart => {
                                switch(dayPart) {
                                    case 'afternoon':
                                        return 'De middag';
                                    case 'morning':
                                        return 'De ochtend';
                                    default:
                                        return dayPart;
                                }
                            })
                            .map(dayPart => {
                                return `${dayPart} is niet meer beschikbaar op
                                    deze datum.`;
                            })
                            .join(' ')}
                        </div>}
                    </div>

                    <div className="flex flex-row gap-8">
                        <CheckboxInput
                            // hasError={errors['daypart']}
                            disabled={selectedDate == null
                                || Object.values(unavailableDayParts).includes('morning')}
                            value={partOfday == "morning"}
                            onChange={e => setPartOfday("morning")}
                        >
                            Ochtend (10:00 - 13:00)
                        </CheckboxInput>

                        <CheckboxInput
                            // hasError={errors['daypart']}
                            disabled={selectedDate == null
                                || Object.values(unavailableDayParts).includes('afternoon')}
                            value={partOfday == "afternoon"}
                            onChange={e => setPartOfday("afternoon")}
                        >
                            Middag (14:00 - 17:00)
                        </CheckboxInput>

                    </div>

                </div>

                <div>
                    <label htmlFor="visitorCount" className="w-full font-proxima font-bold block">Met hoeveel personen kom je helpen inpakken?*</label>
                    <span className="font-light mb-2 lg:mb-4 leading-tight block">Minimaal 10 personen, maximaal 23 personen. Minimale leeftijd is 18+. Het aantal personen is een voorlopig aantal en zal later per mail worden bevestigd.</span>

                    <Counter
                        count={visitorCount}
                        countUp={increaseVisitorCount}
                        countDown={decreaseVisitorCount}
                        countMax={23}
                        countMin={10}
                    />

                </div>

                <div className="flex flex-col gap-2 lg:gap-4 mt-6">

                    <div>
                        <div className="flex font-bold items-center">
                            Extra optie voor lunch (optioneel)
                            <Tooltip />
                        </div>
                        {lunchAvailable || <div className="font-light leading-tight">
                            Lunch is voor deze dag niet meer beschikbaar, omdat
                            een andere groep al lunch heeft deze dag. Dit is
                            per dag maar 1x beschikbaar.
                        </div>}
                    </div>

                    <div className="flex flex-row gap-8">
                        <CheckboxInput
                            // hasError={errors['daypart']}
                            disabled={!lunchAvailable || selectedDate == null}
                            value={!lunch}
                            onChange={() => {
                                setInternalLunchPreference(false);
                                setLunch(false);
                            }}
                        >
                            Zonder lunch
                        </CheckboxInput>

                        <CheckboxInput
                            // hasError={errors['daypart']}
                            disabled={!lunchAvailable || selectedDate == null}
                            value={lunch}
                            onChange={() => {
                                setInternalLunchPreference(true);
                                setLunch(true);
                            }}
                        >
                            Met lunch
                        </CheckboxInput>
                    </div>

                </div>
            </div>
        </div>
    )
}
