import { useState, useEffect } from "react";
import SelectDate from "../Components/SelectDate";
import Calendar from "../Components/Calendar";
import DayCard from "../Components/DayCard";
import DayDetails from "../Components/DayDetails";
import CalendarLegend from "../Components/CalendarLegend";
import Preferences from "../Components/Preferences";
import Information from "../Components/Information";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import NumberOfPeople from "../Components/NumberOfPeople";
import { useCalendarEventContext } from "../Context/CalendarEventContext";
import { DateTime } from "luxon";
import { createHeldenAvondRequest } from "../api/backend";
import LoadingOverlay from "../Components/LoadingOverlay";
import { usePublicSettingsContext } from "../Context/PublicSettingsContext";
import { formatDateFromISO } from "../Utils/date";

export default function HeldenavondSignup(properties) {

    const [privacyAgreed, setPrivacyAgreed] = useState(false);

    const { page } = properties;

    const [message, setMessage] = useState('');
    const [selectedDay, setSelectedDay] = useState(null);
    const [visitorInformation, setVisitorInformation] = useState({
        0: {
            firstName: '',
            lastName: '',
            emailAddress: '',
            phoneNumber: '',
            birthdate: '',
        }
    });

    // Form
    const [invalid, setInvalid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notifications, setNotifications] = useState('');

    const formData = {
        date: selectedDay ? DateTime.fromFormat(selectedDay, 'dd-MM-yyyy').toISODate() : "",
        birthdate: visitorInformation[0].birthdate ? DateTime.fromJSDate(visitorInformation[0].birthdate).toISODate() : '',
        emailAddress: visitorInformation[0].emailAddress,
        firstName: visitorInformation[0].firstName,
        lastName: visitorInformation[0].lastName,
        phoneNumber: visitorInformation[0].phoneNumber,
        comments: message
    }

    const handleSubmitForm = async (e) => {

        e.preventDefault();

        if (loading)
            return;

        setLoading(true);
        setNotifications([]);

        let hasError = false;

        if(!privacyAgreed) {
            setNotifications(prevState => ([...prevState, "privacyAgreed"]))
            hasError = true;
        }

        if (!selectedDay) {
            setNotifications(prevState => ([...prevState, "selectedDay"]))
            hasError = true;
        }

        if (!e.target.checkValidity()) {
            setNotifications(prevState => ([...prevState, "formfields"]))
            hasError = true;
        }

        let participantBirthdate = DateTime.fromISO(formData.birthdate);
        let eventDate = DateTime.fromISO(formData.date);
        let ageOnEventDate = eventDate.diff(participantBirthdate, 'years').toObject().years;
        if (ageOnEventDate < 18) {
            setNotifications(prevState => ([...prevState, "underagedParticipants"]))
            hasError = true;
        }

        if (hasError) {
            setInvalid(true);
            setLoading(false);
            return null;
        }

        const request = await createHeldenAvondRequest(formData);

        if ([200, 201, 204].includes(request.status)) {
            window.location.href = 'https://stichtingjarigejob.nl/heldenavond-bedankt/';
            return;
        }

        setLoading(false);

    }

    return (
        <div>
            <div className="flex flex-col h-full">
                <div className="lg:w-[70vw] p-2 lg:p-5 lg:mx-auto">
                    <h1 className="font-proxima font-black text-2xl lg:text-4xl text-cal-blue">Inschrijven heldenavond</h1>
                </div>
                <div className="lg:w-[70vw] mx-auto lg:p-5">
                    <form
                        onSubmit={handleSubmitForm}
                        className={`bg-[#FAFAFA] ${invalid ? "display-errors" : ""}`}
                        noValidate
                    >
                        <SelectDate page={page} />
                        <CalendarLegend />
                        <ClientCalendar
                            selectedDay={selectedDay}
                            setSelectedDay={setSelectedDay}
                            page={page}
                        />
                        <div className="px-2 lg:invisible">&nbsp;</div>
                        <Information
                            label="2. Vul je gegevens in"
                            visitorInformation={visitorInformation}
                            setVisitorInformation={setVisitorInformation}
                            message={message}
                            setMessage={setMessage}
                            page={page}
                            notifications={notifications}
                            invalid={invalid}
                            privacyAgreed={privacyAgreed}
                            setPrivacyAgreed={setPrivacyAgreed}
                        />
                    </form>
                </div>
            </div>
        </div>
    );
}

function ClientCalendar(properties) {

    const {
        selectedDay,
        setSelectedDay,
        page
    } = properties;

    const {
        unavailableDates,
        calendarEvents,
        unavailableDateLoading,
        heldenavondLoading

    } = useCalendarEventContext();

    const { publicSettings } = usePublicSettingsContext();

    const calendarAvailableTill = formatDateFromISO(publicSettings?.heldenavondCalendarEndDateExclusiveUpperBound || "2024-10-01T00:00:000.Z");

    const dataLoading = heldenavondLoading || unavailableDateLoading;

    const selectDay = (date) => {
        setSelectedDay((prevDate) => prevDate == date ? null : date)
    }

    let dayCards = {};


    // Als een datum niet beschikbaar is
    if (unavailableDates) { 
        Object.values(unavailableDates).map(x => {
            if(!x.heldenavondUnavailable) {
                return;
            }
            dayCards[x.date] = {
                dayLabelClass: 'text-black',
                clickable: false,
                component: <DayCard>
                    <div className="p-2 bg-cal-lighter-gray w-full h-full flex items-center">
                        <p className="text-left ">Niet beschikbaar</p>
                    </div>
                </DayCard>
            } 
        }) 
    }

    Object.keys(calendarEvents).map((date) => {

        if(dayCards[date]) return;


        // Filter alle events er uit die geen Teamuitjes zijn
        let events = Object.values(calendarEvents[date]).filter((event) => {
            if (event.$$type !== "heldenavond") return false;
            return true;
        })

        if (events.length == 0) return;

        // Stoppen als een datum al is volgeboekt ( 21 of meer boekingen voor heldenavond ).
        if (events.length >= 21) {

            dayCards[date] = {
                dayLabelClass: 'text-white',
                clickable: false,
                component: <DayCard>
                    <div className="p-2 bg-cal-lighter-gray w-full h-full flex items-center">
                        <p className="text-left ">Niet beschikbaar</p>
                    </div>
                </DayCard>
            }
            return;

        }

        // Melding tonen als er nog X aantal plekken beschikbaar zijn.
        if (events.length >= 1 && events.length <= 20) {
            dayCards[date] = {
                dayLabelClass: 'text-black',
                component: <DayCard>
                    <div className="w-full h-full flex flex-col justify-center p-2">
                        <ul className="flex flex-col text-left gap-1">
                            <li key={events.length} className="text-xs flex flex-row gap-2 items-center">
                                <div className={`w-2 h-2 aspect-square bg-red-400`}></div>
                                Nog {21 - (events.length)} plekken beschikbaar
                            </li>
                        </ul>
                    </div>
                </DayCard>
            }
        }

        return;

    })

    if (selectedDay) {
        dayCards[selectedDay] = {
            dayLabelClass: 'text-white',
            component: <DayCard>
                <div className="bg-cal-blue text-white w-full h-full flex justify-center items-center gap-2">
                    <CheckCircleIcon className="w-8 text-white" /> <span className="hidden lg:block font-proxima">Geselecteerde datum</span>
                </div>
            </DayCard>,
        }
    }

    const PassedDayCard = <DayCard>
        <div className="p-2 bg-cal-lighter-gray w-full h-full flex items-center">
            <p className="text-left ">Niet beschikbaar</p>
        </div>
    </DayCard>

    const NotAvailableYetDayCard = <DayCard>
        <div className="p-2 bg-cal-lighter-gray w-full h-full flex items-center">
            <p className="text-left ">(Nog) niet beschikbaar</p>
        </div>
    </DayCard>

    return <div className="flex flex-col gap-2 items-center">
        <div className="relative w-full">
            <LoadingOverlay loading={dataLoading} />
            <Calendar
                calendarAvailableTill={calendarAvailableTill}
                dayCards={dayCards}
                HeldenavondSignup={true}
                NotAvailableYetDayCard={NotAvailableYetDayCard}
                onDayClick={selectDay}
                page={page}
                passedDayCard={PassedDayCard}
            />
        </div>
        <div>
            Jouw geselecteerde datum: {selectedDay == null
            ? 'kies een beschikbare datum'
            : <span className="font-bold">{DateTime
                .fromFormat(selectedDay, 'dd-MM-yyyy')
                .setLocale('nl-NL')
                .toLocaleString(DateTime.DATE_HUGE)}</span>}
        </div>
    </div>

}
