import { useRef } from 'react'
import { useState } from "react"
import JobStanding from './job-standing.png'
import PersonInformationFields from "./PersonInformationFields"
import CompanyInformationFields from "./CompanyInformationFields"
import DisplayNotifications from "./Elements/DisplayNotifications"
import CheckboxInput from "./Forms/Controls/CheckboxInput";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

/**
 * Properties -> React.Element
 * @param properties.companyInformation Object String
 * @param properties.invalid Boolean
 * @param properties.label String
 * @param properties.loading Boolean
 * @param properties.message String
 * @param properties.notifications Array String
 * @param properties.page String
 * @param properties.privacyAgreed Boolean
 * @param properties.setCompanyInformation Object String -> Any
 * @param properties.setMessage String -> Any
 * @param properties.setPrivacyAgreed (Boolean -> Any) -> Any
 * @param properties.setVisitorInformation (Object (Object Any)) -> Any
 * @param properties.visitorCount Integer
 * @param properties.visitorInformation Object (Object Any)
 * @return React.Element
 */
export default function Information(properties) {

    // const [privacyAgreed, setPrivacyAgreed] = useState(false);

    const {
        visitorCount,
        page,
        visitorInformation,
        setVisitorInformation,
        companyInformation,
        setCompanyInformation,
        message,
        setMessage,
        invalid,
        loading,
        notifications,
        label,
        setPrivacyAgreed,
        privacyAgreed
    } = properties;

    const commentLengthReference = useRef(null)

    const onCommentChange = event => {

        const { value } = event.target

        if (commentLengthReference.current != null)
            commentLengthReference.current.textContent = value.length

        setMessage(value)

    }

    return (
        <div className="mx-2">
            <h2 className="font-proxima font-black text-xl mb-2">{label || "3. Vul je gegevens in"}</h2>

            <div className="relative shadow rounded-xl border-1 border-grey p-5 font-proxima bg-[#FFFFFF] lg:flex lg:flex-wrap lg:gap-x-8">

                {[...Array(visitorCount)].map((e, i) => {

                    //Alleen bij Aanpakker voor een dag is er per bezoeker informatie nodig.
                    if (page !== "aanpakker" && i >= 1) {
                        return;
                    }

                    return (
                        <PersonInformationFields
                            number={i + 1}
                            key={i}
                            index={i}
                            page={page}
                            visitorInformation={visitorInformation}
                            setVisitorInformation={setVisitorInformation}
                            invalid={invalid}
                        />
                    )
                }
                )}

                {/* Alleen bij Teamuitjes is bedrijfsinformatie nodig */}
                {page !== "heldenavond" &&
                    <CompanyInformationFields
                        companyInformation={companyInformation}
                        setCompanyInformation={setCompanyInformation}
                        invalid={invalid}
                    />
                }

                <div className="lg:w-2/3">
                    <p className="w-full font-proxima font-bold block mb-4">
                        Vragen of opmerkingen (let op:
                        { ' ' }
                        <span ref={commentLengthReference}>0</span>/255 leestekens)
                    </p>

                    <textarea
                        maxLength={255}
                        name="message"
                        id="message"
                        cols="30"
                        rows="10"
                        placeholder="Bericht of opmerking"
                        className="w-full rounded-xl border-2 border-grey px-4 py-2 font-proxima"
                        onChange={onCommentChange}
                        value={message}
                    ></textarea>
                </div>

                <DisplayNotifications
                    notifications={notifications}
                />
                <div className="lg:w-2/3 mt-6 lg:pr-10 xl:pr-0">
                    <CheckboxInput
                        // hasError={errors['daypart']}
                        // disabled={unavailableDayParts.indexOf('afternoon') > -1}
                        value={privacyAgreed}
                        justifyLabel="justify-start"
                        required
                        onChange={e => setPrivacyAgreed(x => !x)}
                    >
                        Ik geef stichting jarige job toestemming om mijn gegevens te verwerken en ga akkoord met de <a target="_blank" href="https://stichtingjarigejob.nl/privacyverklaring/"><b>privacyverklaring</b></a>.
                    </CheckboxInput>
                </div>
                
                <div className="w-full mt-6">
                    <button
                        type="submit"
                        disabled={loading}
                        className="w-fit bg-[#9A40C9] border-4 border-[#9A40C9] text-white font-proxima text-xs py-3 px-12 rounded-[40px] uppercase font-bold transition-all hover:border-4 hover:border-[#6f2a92] hover:border-offset-0 hover:shadow-lg hover:translate-y-[-4px] disabled:opacity-50"
                    >Verzenden
                    </button>
                </div>

                <img src={JobStanding} alt="Job Standing" width="350px" height="auto" className="absolute -bottom-[4rem] -right-[3rem] hidden lg:block" />

            </div>


        </div>

    )



}
